import React, { useEffect } from "react";
import { Login } from "./views/ClientLogin/Login";
import { oktaConfig } from "./lib/oktaConfig";
import { useDispatch } from "react-redux";
import { SecureViews } from "./views/SecureViews/SecureViews";
import { setBrowserType } from "./redux/ui/actions";
import { getBrowserType } from "./utils/utils";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import AppRoutes from './routes';
import { store } from "./store/store";
import { Provider } from "react-redux";


const CALLBACK_PATH = "/login/callback";
const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
	const dispatch = useDispatch();
	// const { browserType, userOpen } = useSelector((state) => state.ui);

	const setAvailableArea = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};

	useEffect(() => {
		setAvailableArea();
		window.addEventListener("resize", setAvailableArea);
		return () => window.removeEventListener("resize", setAvailableArea);
	});

	useEffect(() => {
		const browser = getBrowserType();
		dispatch(setBrowserType(browser));
	}, []);

	const history = useHistory();
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	return (
		<div className="app--main--container">
			{/*<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route path={CALLBACK_PATH} exact component={LoginCallback} />
					<SecureViews />
				</Switch>
			</Security>*/}
			<AppRoutes />
		</div>
	);
};

export default App;
