import React, {useState} from "react";
import axios from "axios";

const PodcastService = () => {

    const [response,setResponse] = useState();

    const fetchPodcasts = () => {
        return axios.get('https://www.workperfectly.com/api/show/8598/rss').then( res => {
            if(res) setResponse(res['data'])
        }).catch( err =>
            console.log('fetch podcast error',err)
        )
    }

    return {
        fetchPodcasts,
        response
    }
}

export default PodcastService;