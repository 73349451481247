export const types = {
	USER_OPEN: "USER_OPEN",
	REQUEST_SHOWS_LOADING: "REQUEST_SHOWS_LOADING",
	REQUEST_SHOWS_SUCCESS: "REQUEST_SHOWS_SUCCESS",
	REQUEST_SHOWS_FAILURE: "REQUEST_SHOWS_FAILURE",
	/* ****************************************** */
	SET_BROWSER_TYPE: "SET_BROWSER_TYPE",
	/* ****************************************** */
	SET_SELECTED_PODCAST: "SET_SELECTED_PODCAST",
	OPEN_SELECTED_PODCAST: "OPEN_SELECTED_PODCAST",
	/* ****************************************** */
	SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
};
