import React from "react";
import "./Login.css";
import loginPlaceholder from "../../assets/images/login/Podcast_People.png";
import {Button} from "antd";
import { GoogleLogin } from 'react-google-login';
import {useDispatch} from "react-redux";
import {initLogin} from "../../redux/auth/actions";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import {useHistory} from "react-router-dom";

const Login = () => {

	const dispatch = useDispatch();
	const history = useHistory();

	const handleSuccess = (response) => {
		let googleUser = {
			accessToken: response.accessToken,
			name: response.name,
			image: response.imageUrl
		};

		if(!googleUser?.accessToken) return;

		localStorage.setItem('google-access-token', googleUser.accessToken);

		dispatch(initLogin());

		setTimeout(()=>history.push(appRoutes.PODCASTS))
	}

	const handleFailure = (response) => {
		console.log('failure',response);
	}

	return (
		<div className="app-wrapper login">
			<div className='login__container'>
				<div className='login__column1'>
                    <div className='login__content'>
						Welcome to the ActiveCampaign Podcasting Network.
					</div>
					<GoogleLogin
						clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
						render={renderProps => (
							<Button type={'primary'}
									onClick={renderProps.onClick}
									className='login__action'
							>
								SIGN IN
							</Button>
						)}
						onSuccess={handleSuccess}
						onFailure={handleFailure}
						isSignedIn={true}
						uxMode={'redirect'}
						redirectUri={process.env.REACT_APP_REDIRECT_URI}
						scope={'profile'}
						responseType={'token'}
						cookiePolicy={'single_host_origin'}
					/>

				</div>
				<div className='login__column2'>
					<img src={loginPlaceholder} alt='' className='login__placeholder'/>
				</div>
			</div>
		</div>
	);
};

export default Login;
