import activeCampaignLogo from "../../../assets/images/login/ActiveCampaign Logo_Blue_Reverse.jpg";
import React from "react";
import './topNavBar.css';
import {useDispatch, useSelector} from "react-redux";
import {initLogout} from "../../../redux/auth/actions";
import {GoogleLogout} from "react-google-login";
import {useHistory} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

const TopNavBar = () => {

    const {auth} = useSelector( state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogoutSuccess = () => {
        dispatch(initLogout());
        localStorage.clear();
        setTimeout(()=>history.push(appRoutes.LOGIN));
    }

    const handleLogoutError = (error) => {
        console.log('Logout error',error)
    }

    return(
        <div className="app__header">
            <img src={activeCampaignLogo} alt='' className='app__header--logo'/>
            {auth && <GoogleLogout clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          render={renderProps => (
                              <span onClick={renderProps.onClick}
                                      className='app_header__action'
                              >
                                  Log out
                              </span>
                          )}
                          onLogoutSuccess={handleLogoutSuccess}
                          onFailure={handleLogoutError}
                          isSignedIn={true}
            />}
        </div>
    )
}

export default TopNavBar;