import React, {useEffect, useRef, useState} from "react";
import './listPodcasts.css';
import PodcastService from "../../services/PodcastService/podcast.service";
import moment from "moment";
import loginPlaceholder from "../../assets/images/login/Podcast_People.png";
import 'react-h5-audio-player/lib/styles.css';
import {parseString} from 'xml2js';
import {UndoOutlined, RedoOutlined} from "@ant-design/icons";

const ListPodcasts = () => {

    let audioRef=useRef([]);

    const { fetchPodcasts, response } = PodcastService();
    const [parsedXml, setParsedXml] = useState();
    const [podcastItems, setPodcastItems] = useState([]);

    useEffect(()=>{
        fetchPodcasts().then()
    },[])

    useEffect(()=>{
        setPodcastItems([])
        response && parseString(response,(err, result) => {
                console.log('err',err);
                setParsedXml(result['rss']['channel'][0]);
            }
        );
    },[response])

    useEffect(()=>{
        parsedXml?.item?.length &&
        setPodcastItems(
            parsedXml?.item?.sort((a, b) => moment(b.pubDate[0]).diff(moment(a.pubDate[0])))
        );
    },[parsedXml])

    return (
        <div className="app-wrapper list-podcast">
            <div className={"list-podcast__column1"}>
                <div className="list-podcast__title">{parsedXml?.title[0]}</div>
                <div className="list-podcast__description">{parsedXml?.description[0]}</div>
            </div>
            <div className='list-podcast__column2'>
                <img src={loginPlaceholder} alt='' className='login__placeholder'/>
            </div>
            <div className='list-podcast__column3'>
                <img src={parsedXml?.image[0].url} alt=''/>
            </div>
            <div className='list-podcast__column4'>
                {podcastItems?.map((item,i) =>
                    <div className="list-podcast__item" key={i}>
                        <div className={"list-podcast__content"}>
                            <div className={"list-podcast__item__title"}>Episode {item.title[0]}</div>
                            <div className={"list-podcast__item__description"}>{item.description[0]}</div>
                            <div className={"list-podcast__item__created"}>
                                {item['dc:creator'][0]} /
                                {moment(item?.pubDate[0]).format(' DD MMMM YYYY')} /
                                {moment.utc(item.duration[0]*1000).format(' HH:mm:ss')}
                            </div>
                            <div className={"list-podcast__item__content"}>
                                <div className={'list-podcast__player__controls'}>
                                    <UndoOutlined onClick={()=>{
                                        const audio = audioRef?.current[i]
                                        audio.currentTime = audio?.currentTime - 15
                                    }}/>
                                    <RedoOutlined onClick={()=>{
                                        const audio = audioRef?.current[i]
                                        audio.currentTime = audio?.currentTime + 15
                                    }}/>
                                </div>
                                <audio controlsList={'nodownload'}
                                       controls
                                       className={'list-podcast__player'}
                                       ref={el => audioRef.current[i]=el}
                                       key={"audio_"+i}
                                       src={item?.link[0]}
                                >
                                </audio>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ListPodcasts;